import React from 'react'
import { SimpleBox } from '../../SimpleBox'
import { Image, Label } from 'semantic-ui-react'
const PlanCheckMarkIcon = require('~assets/images/icons/plan-check-mark.svg')

const Tags: React.FC<{
  activateNow: boolean;
  trialEnabled?: boolean;
  reduced?: boolean;
}> = ({ activateNow, trialEnabled, reduced }) => (
  <SimpleBox display="inline-block" ml="auto" className="checkout-tags">
    <Label.Group color="grey" size="large">
      {!activateNow && (
        <Label className="label-text">
          <Image as="img" src={PlanCheckMarkIcon} height={20} width={20} />
          7 Day Free Trial
        </Label>
      )}
      <Label className="label-text">
        <Image as="img" src={PlanCheckMarkIcon} height={20} width={20} />
        {(trialEnabled || reduced) ? '30' : '60'} Day Money-Back Guarantee
      </Label>
    </Label.Group>
  </SimpleBox>
)

export default Tags
