import React, {FC, useState} from 'react'
import {Button, Image } from 'semantic-ui-react'
import { SimpleBox } from '../SimpleBox'
import Mutation from '../../shared/mutation'
import Notifications from '../../shared/notifications'

const onboardingImage = require('../../assets/images/identity-setup-onboarding.png')

const ACTIVATE_MUTATION = `mutation activateIdentisafe {
  activateIdentisafe
}`

type Props = {
  onSetupComplete: () => void
}

const Onboarding:FC<Props> = ({ onSetupComplete }) => {
  const activateMutation = new Mutation<{ activateIdentisafe: boolean }>(ACTIVATE_MUTATION)
  const [loading, setLoading] = useState(false)

  const beginSetup = () => {
    setLoading(true)
    activateMutation.exec().then(() => {
      if (activateMutation.hasErrors) {
        Notifications.error(activateMutation.error())
      } else if (activateMutation.data?.activateIdentisafe) {
        Notifications.success('Your IdentiSafe Account is setup!')
        onSetupComplete()
      }
      setLoading(false)
    })
  }

  return (
    <SimpleBox className="onboarding">
      <Image src={onboardingImage} />
      <h5>Go through onboarding to activate protection</h5>
      <SimpleBox className="steps" marginTop="33px" marginBottom="33px">
        <SimpleBox className="step">
          <span className="dot"></span>
          <span className="text">Personal Information</span>
        </SimpleBox>
        <SimpleBox className="step">
          <span className="dot"></span>
          <span className="text">Dark Web Monitoring</span>
        </SimpleBox>
      </SimpleBox>
      <Button primary={true} size="large" loading={loading} onClick={beginSetup}>
        Set up
      </Button>
    </SimpleBox>
  )
}

export { Onboarding }
