/* eslint-disable complexity */
import React, {FC, useEffect, useRef, useState, useCallback, useMemo} from 'react'
import {gql, useMutation, useQuery} from '@apollo/client'
import {set} from 'dot-prop'
import {Form, FormField, Grid, GridColumn, GridRow, Image, Label, Modal} from 'semantic-ui-react'
import {Plans} from '../../components/Plans'
import {SimpleBox} from '../../components/SimpleBox'
import {SimpleCard} from '../../components/SimpleCard'
import {SimpleForm} from '../../components/SimpleForm'
import {Pane, SimpleTab} from '../../components/SimpleTab'
import {SimpleText} from '../../components/SimpleText'
import {observer} from '../../decorators'
import {Discount} from '../../fragments/modal/payment/Discount'
import {Breakdown} from '../../fragments/payment/Breakdown'
import {Submit} from '../../fragments/payment/SubmitNew'
import {toMoney} from '../../shared/format'
import Router from '../../shared/router'
import {CHARGEBEE_PAYMENT_SOURCE_TYPES as PAYMENT_TYPES, ChargebeeItemPrice} from '../../shared/types'
import {Plan} from '../../type'
import {useNavigation} from '../../hooks/useNavigation'
import {DiscountWarning} from '../../fragments/modal/payment/DiscountWarning'
import {ChargebeeCoupon, FetchCoupon} from '../../utils/coupon'
import {Upsell} from '../../fragments/payment/Upsell'
import {ActivateNow} from '../../fragments/ActivateNow'
import {PaymentData} from '../../fragments/payment/ICardProps'
import {CardComponent} from '../../fragments/payment/CardComponent'
import {trackBeginCheckout, trackEmailValidationSuccess, trackPurchase} from '../../utils/tracking'
import {
  calculateCouponDiscount,
  parsePayPalReturnData,
  generateFeaturesList,
  resolveFreeMonths,
} from '../../graphql/checkout'
import Session from '../../shared/storages/session'
import {nameRegex, emailRegex} from '../../const'
import Decimal from 'decimal.js'

type CheckoutConfiguration = {
  checkout: {
    id: string
    checkoutConfiguration: {
      id: string
      freeMonths: number
      payNowEnabled: boolean
      allowApplyCoupon: boolean
      reducedMoneyBackGuarantee: boolean
    }
  }
}

const ExternalLink: FC<{ path: string, text: string }> = ({ path, text }) => (
  <a href={`https://virtualshield.com/${path}`} target="_blank" rel="noreferrer">{text}</a>
)

const PaymentPayPalIcon = require('~assets/images/icons/payment-paypal.svg')
const PaymentCardsImage = require('~assets/images/payment-cards.svg')
const PlanCheckMarkIcon = require('~assets/images/icons/plan-check-mark.svg')

const PLAN_PERIOD_MAP = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
  YEAR: 3,
}

const GQL_CREATE_CHECKOUT = gql`
  mutation($affiliateId: ID) {
    checkout: createCheckoutSubscription(affiliateId: $affiliateId) {
      id checkoutConfiguration {
        id freeMonths payNowEnabled allowApplyCoupon reducedMoneyBackGuarantee
      }
    }
  }
`

const GQL_PLANS = gql`
  query($id: ID!) {
    plan(id: $id) {
      id
      name: externalName
      description
      price
      period
      periodUnit
      pricingModel
      currencyCode
      trialPeriod
      trialPeriodUnit
      billingCycles
      freeQuantity
      itemFamily { name }
      item { id featuresList users metadata }
      __typename
    }
  }
`

type PriceProperties = 'id' | 'name' | 'price' | 'trialPeriod'
export type Price = Pick<ChargebeeItemPrice, PriceProperties>
export type CardDetails = { number: string; expiry: string; cvv: string; token?: string }

type CouponWarningProps = { coupon: ChargebeeCoupon, plan: Pick<Plan, 'id' | 'name'> }
type Props = { trialEnabled: boolean, promotion: boolean }

const CheckoutProduct: FC<Props> & { authorize: boolean; auth: string; } = props => {
  // States & Variables
  const price = Router.params.price as string
  const priceIds = price.split(':') || []
  const paypalData = parsePayPalReturnData()

  const { setNav, resetNav } = useNavigation()

  const cardRef = useRef<any>()
  const captchaRef = useRef<any>()
  const fieldsetRef = useRef<HTMLFieldSetElement>(null)
  const loadingState = useState<boolean>(!!paypalData.result)
  const [trialEnabled, setTrialEnabled] = useState(props.trialEnabled && Router.qs.noTrial === undefined)
  const [currency, setCurrency] = useState<string | undefined>()
  const [firstPlanId, setFirstPlanId] = useState(priceIds[0])
  const [activateNow, setActivateNow] = useState<boolean>(paypalData.activateNow)
  const [coupon, setCoupon] = useState<ChargebeeCoupon | undefined>(undefined)
  const [couponWarningPayload, setCouponWarningPayload] = useState<CouponWarningProps>()
  const [type, setType] = useState<PAYMENT_TYPES>(Router.qs.paypal ? 'PAYPAL' : 'CARD')
  const [modal, setModal] = useState<'discount-warning' | undefined>(undefined)
  const [cardErrors, setCardErrors] = useState<string[]>([])
  const [data, setData] = useState<PaymentData>({
    email: (paypalData.email || (Router.qs.email as string) || '').trim().replace(/ /g, '+'),
    firstName: '',
    lastName: '',
    zip: '',
    card: { number: '', expiry: '', cvv: '' },
    paypal: {},
    captcha: false,
  })

  // GraphQL
  const { data: planData, loading } = useQuery<{ plan: Plan }>(GQL_PLANS, { variables: { id: firstPlanId } })
  const [startCheckout, { data: checkoutData }] = useMutation<CheckoutConfiguration>(GQL_CREATE_CHECKOUT)

  // Shortcuts
  const plan = planData?.plan
  const isFamily = (plan?.item?.users || 1) > 1
  const months = (plan?.period || 0) * (plan?.periodUnit === 'YEAR' ? 12 : 1)
  const planPrice = new Decimal(plan?.price || '0')
  const priceTotal = planPrice.minus(calculateCouponDiscount(planPrice, coupon))
  const checkout = checkoutData?.checkout?.checkoutConfiguration

  // Effects
  useEffect(() => {
    const checkoutDataCache = JSON.parse(localStorage.getItem('vs_checkout_cache') ?? '{}')
    if (!loadingState[0] && Object.keys(checkoutDataCache).length > 0) {
      setData(checkoutDataCache.data)
      setType(checkoutDataCache.type)
      checkoutDataCache.coupon && setCoupon(checkoutDataCache.coupon)
      checkoutDataCache.activateNow !== undefined && setActivateNow(checkoutDataCache.activateNow)
    }

    const variables = { affiliateId: Session.affiliate.affiliateId, planId: firstPlanId }
    startCheckout({ variables }).then(({ data }) => {
      (data && !data.checkout.checkoutConfiguration.payNowEnabled) && setTrialEnabled(false)
    })

    setNav('no-sign-in')
    return resetNav
  }, [])

  useEffect(() => {
    if (plan) {
      setCurrency(plan.currencyCode)
      Session.currency = plan.currencyCode
      trackBeginCheckout(plan, isFamily, coupon, 'plan')
    }
  }, [plan])

  useEffect(() => {
    localStorage.setItem('vs_checkout_cache', JSON.stringify({
      id: firstPlanId, coupon, activateNow, data, type,
    }))
  }, [firstPlanId, coupon, activateNow, data, type])

  useEffect(() => {
    fieldsetRef.current && paypalData.result && fieldsetRef.current.querySelector('.submit-box')?.scrollIntoView()
  }, [fieldsetRef.current])

  useEffect(() => { !loadingState[0] && setActivateNow(!trialEnabled) }, [trialEnabled])

  // Callbacks
  const handleTabChange = useCallback((index: number) => {
    setType(index === 0 ? 'CARD' : 'PAYPAL')
  }, [])

  const handleEmailChange = useCallback((value: string) => {
    value = value.toLocaleLowerCase()
    emailRegex.test(value) && trackEmailValidationSuccess()
    setData(d => ({ ...set(d, 'email', value) }))
  }, [])

  const handleDataChange = useCallback(({ name, value }: { name: string; value: any }) => {
    if ((name !== 'firstName' && name !== 'lastName') || (value === '' || nameRegex.test(value))) {
      setData(d => ({ ...set(d, name, value) }))
    }
  }, [])

  const handleCouponChange = useCallback((coupon?: ChargebeeCoupon) => {
    setCoupon(coupon)
    const action = coupon ? 'add coupon' : 'remove coupon'
    plan && trackBeginCheckout(plan, isFamily, coupon, action)
  }, [plan])

  const handleChangePlan = useCallback((selectedPlan: Pick<Plan, 'id' | 'name' | 'period' | 'periodUnit'>) => {
    FetchCoupon(selectedPlan.id, coupon?.id).then(loadedCoupon => {
      let path = `/${location.pathname.split('/')[1]}/${selectedPlan.id}`
      loadedCoupon && (path += `?coupon=${loadedCoupon.id}`)
      Router.updateHistory(path)
      setFirstPlanId(selectedPlan.id)
      loadedCoupon && setCoupon(loadedCoupon)
      if (selectedPlan.period === 1 && selectedPlan.periodUnit === 'MONTH' && activateNow && trialEnabled) {
        setActivateNow(false)
      }
    }).catch(() => {
      if (coupon) {
        setCouponWarningPayload({ coupon: coupon, plan: selectedPlan })
        setModal('discount-warning')
      }
    })
  }, [coupon])

  const handleWarnedCoupon = useCallback(() => {
    if (couponWarningPayload) {
      const path = location.pathname.split('/')[1]
      Router.updateHistory(`/${path}/${couponWarningPayload.plan.id}`)
      setFirstPlanId(couponWarningPayload.plan.id)
      setCoupon(undefined)
      setModal(undefined)
    }
  }, [])

  const points = useMemo(() => (
    plan?.item && generateFeaturesList(plan?.item, activateNow)
  ), [plan?.item, activateNow])

  const planDetail = useMemo(() => (!plan ? undefined : {
    period: plan.period,
    periodUnit: PLAN_PERIOD_MAP[plan.periodUnit],
  }), [plan])

  const defaultPromo = useMemo(() => (
    activateNow && props.promotion && (typeof checkout?.freeMonths) !== 'number'
  ), [activateNow, checkout])

  const breakdownMarkup = (
    <>
      <Breakdown
        total={toMoney(priceTotal.toNumber())}
        price={planPrice.toNumber()}
        coupon={coupon}
        activateNow={activateNow}
      />
      {trialEnabled && <ActivateNow
        months={months}
        activateNow={activateNow}
        setActivateNow={setActivateNow}
        bienniallyFreeMonths={resolveFreeMonths(props.promotion, checkout?.freeMonths)}
      />}
    </>
  )

  const paymentPanes: Pane[] = [
    {
      type: 'CARD',
      icon: PaymentCardsImage,
      title: 'Credit Card',
      render: <CardComponent
        data={data}
        forwardRef={cardRef}
        captchaRef={captchaRef}
        onChange={handleDataChange}
        onErrors={setCardErrors}
      />,
    },
    {
      type: 'PAYPAL',
      icon: PaymentPayPalIcon,
      title: 'PayPal',
      render: <></>,
    },
  ]

  const tagsMarkup = (version: string) => (
    <SimpleBox display="inline-block" ml="auto" className={version}>
      <Label.Group color="grey" size="large">
        {!activateNow && (
          <Label>
            <Image as="img" src={PlanCheckMarkIcon} height={20} width={20} />
            7 Day Free Trial
          </Label>
        )}
        <Label>
          <Image as="img" src={PlanCheckMarkIcon} height={20} width={20} />
          {(trialEnabled || checkout?.reducedMoneyBackGuarantee) ? '30' : '60'} Day Money-Back Guarantee
        </Label>
      </Label.Group>
    </SimpleBox>
  )

  const discountFormMarkup = (version: 'mobile' | 'desktop') => (
    <div className={`discountForm-${version}`}>
      <Discount
        planId={firstPlanId}
        onCouponFound={handleCouponChange}
        onRemoveCoupon={handleCouponChange}
        hidden={!checkout?.allowApplyCoupon}
      />
    </div>
  )

  const buttonDisabled = type === 'CARD' && cardErrors.length > 0

  if (loading || !currency) {
    return null
  } else if (!plan && !loading) {
    Router.redirect('/pricing')
    return null
  }

  return (
    <fieldset disabled={loadingState[0]} style={{ margin: 0, padding: 0, border: 'none' }} ref={fieldsetRef}>
      {/* modal */}
      <Modal closeIcon size="tiny" open={modal !== undefined} onClose={() => setModal(undefined)}>
        <DiscountWarning
          isOpen={modal === 'discount-warning'}
          payload={couponWarningPayload}
          onFinish={handleWarnedCoupon}
        />
      </Modal>
      {/* page */}
      <SimpleBox mt={15} p={4} className="checkout">
        <div className="plan-selected">
          <span>Plan selected:<b> {plan?.name}</b></span>
        </div>
        <SimpleBox maxWidth={1240} mx="auto">
          <Form name={'checkout'}>
            {/* account */}
            {tagsMarkup('mobile')}
            <SimpleBox mt={2} display="flex" alignItems="center" gap={18} width="100%">
              <SimpleCard color="grey" style={{ flex: '0 0 40px' }} base>
                <SimpleBox height={40} width={40} display="grid" alignContent="center">
                  <SimpleText size="title1">1</SimpleText>
                </SimpleBox>
              </SimpleCard>
              <SimpleText size="large">
                Enter Your Email Address
                <span className="enter-email" />
              </SimpleText>
              {tagsMarkup('desktop')}
            </SimpleBox>
            <SimpleBox mt={4}>
              <SimpleCard fluid padding="heavy">
                <FormField>
                  <label htmlFor="email">Enter email address</label>
                  <Grid>
                    <GridRow verticalAlign="middle">
                      <GridColumn width="10">
                        <SimpleForm.Input
                          type="email"
                          value={data?.email}
                          onChange={(d: any) => handleEmailChange(d.value)}
                          size="large"
                          required
                          fluid
                          showValidation={true}
                        />
                      </GridColumn>
                      <GridColumn width="6">
                        <div className="text--x-small"><span className="text">Privacy Guarantee:</span> We do not share your
                         information and will contact you only as needed to provide our service.</div>
                      </GridColumn>
                    </GridRow>
                  </Grid>
                </FormField>
              </SimpleCard>
            </SimpleBox>
            {/* payment */}
            <SimpleBox mt={4} display="flex" alignItems="center" gap={18} width="100%">
              <SimpleCard color="grey" style={{ flex: '0 0 40px' }} base>
                <SimpleBox height={40} width={40} display="grid" alignContent="center">
                  <SimpleText size="title1">2</SimpleText>
                </SimpleBox>
              </SimpleCard>
              <SimpleText size="large">
                Select Your Preferred Payment Method
                <span className="payment-method" />
              </SimpleText>
            </SimpleBox>
            {discountFormMarkup('mobile')}
            <SimpleBox mt={2}>
              <div className="grid grid-checkout">
                <div className="grid-checkout-a">
                  <SimpleTab
                    key={type}
                    onChange={handleTabChange}
                    panes={paymentPanes}
                    initial={type === 'CARD' ? 0 : 1}
                    footer={
                      <>
                        {breakdownMarkup}
                        <SimpleBox mt={3} className="submit-box">
                          <Submit
                            type={type}
                            data={data}
                            coupon={coupon}
                            cardRef={cardRef}
                            planDetail={planDetail}
                            captchaRef={captchaRef}
                            priceIds={[firstPlanId]}
                            promotion={defaultPromo}
                            disabled={buttonDisabled}
                            activateNow={activateNow}
                            loadingState={loadingState}
                            price={priceTotal.toNumber()}
                            checkoutConfigId={checkout?.id}
                            subscriptionId={checkoutData?.checkout?.id}
                            paypalReturnPath={`/${location.pathname.split('/')[1]}`}
                            purchaseFinished={() => trackPurchase(plan!, isFamily, activateNow, coupon)}
                          />
                        </SimpleBox>
                        <SimpleBox mt={3}>
                          <div className="text--x-small">
                            By signing up for our service, you agree to our
                            {' '}<ExternalLink path="legal/terms/" text="Terms of Service" /> and acknowledge our
                            {' '}<ExternalLink path="legal/privacy/" text="Privacy Policy" />. To ensure uninterrupted
                            {' '}access, your subscription will automatically renew at the end of each billing cycle
                            using the payment method provided until you cancel it by disabling auto-renewal, which can
                            be done at any time.
                          </div>
                        </SimpleBox>
                      </>
                    }
                  />
                </div>
                {plan && (
                  <div className="grid-checkout-b">
                    <SimpleCard title="Selected Plan">
                      <Plans.Single
                        plan={plan}
                        coupon={coupon}
                        points={points || []}
                        price={priceTotal.toNumber()}
                        onChangePlan={handleChangePlan}
                        showPlanSelection={!Session.affiliate.affiliateId && paypalData.result !== 'completed'}
                        hidePrices
                      />
                    </SimpleCard>
                    {discountFormMarkup('desktop')}
                    <Upsell currentPlan={plan} onUpgrade={handleChangePlan} coupon={coupon} />
                    <Plans.Trial
                      trialEnabled={!activateNow && trialEnabled}
                      reducedMoneyBackGuarantee={checkout?.reducedMoneyBackGuarantee}
                    />
                  </div>
                )}
              </div>
            </SimpleBox>
          </Form>
        </SimpleBox>
      </SimpleBox>
    </fieldset>
  )
}

CheckoutProduct.defaultProps = {
  trialEnabled: true,
  promotion: true
}

CheckoutProduct.authorize = false
CheckoutProduct.auth = '/apps'

export default observer(CheckoutProduct)
