import React, { FC } from 'react'
import { Alerts } from './alerts/Alerts'
import { ProtectionArea } from './protectionArea'
import {gql, useQuery} from '@apollo/client'
import {Loader} from 'semantic-ui-react'
import {LaunchIdentisafe} from './LaunchIdentisafe'

const Dashboard: FC = () => {
  const { data, loading } = useQuery(
    gql`
      query identisafe {
          identisafeInformation {
              disabled
              issueDetected
              personalInformation
              darkWebMonitoring
              alerts
          }
      }`
    , { fetchPolicy: 'no-cache' })

  const isLoading = (data === null || data === undefined) && loading

  return (<div>
    {isLoading && (<Loader active />)}
    {!isLoading && data && (<ProtectionArea data={data.identisafeInformation} />)}
    {!isLoading && data && (<Alerts alerts={data.identisafeInformation.alerts} />)}
    {!isLoading && data && (<LaunchIdentisafe />)}
  </div>)
}

export { Dashboard }
