import React, { useMemo } from 'react'
import { SimpleBox } from '../../SimpleBox'
import { SimpleCard } from '../../SimpleCard'
import { SimpleText } from '../../SimpleText'
import { Points } from '../../Plans/components/Single/components'
import { Checkbox, Label } from 'semantic-ui-react'
import Breakdown from '../Breakdown'
import { ChargebeeCoupon } from '../../../utils/coupon'
import { Addon, Plan } from '../../../type'
import { Discount } from '../../../fragments/modal/payment/DiscountNew'
import { Feature } from '../../../types/checkout'
import { toMoneyWithoutCalculation as toMoney } from '../../../shared/format'
import { calculateCouponDiscount, generateFeaturesList } from '../../../graphql/checkout'
import Decimal from 'decimal.js'
import SummaryCardHeader from './HeaderComponent'

const ShieldRedIcon = require('~assets/images/icons/shield-red.svg')
const AlertIcon = require('~assets/images/icons/icon-alert.svg')

interface SummaryCardProps {
  total: number
  coupon?: ChargebeeCoupon
  activateNow: boolean
  plan?: Plan
  extraUserAddon?: Addon
  addAnotherUser: boolean
  handleToggleAddUser: () => void
  firstPlanId: string
  handleCouponChange: (coupon?: ChargebeeCoupon) => void
  selectedFeatures: Feature[]
  bienniallyFreeMonths?: number
  priceWithAddon: number
  allowCoupon?: boolean
}

const SummaryCard: React.FC<SummaryCardProps> = ({
  total,
  coupon,
  activateNow,
  plan,
  extraUserAddon,
  addAnotherUser,
  handleToggleAddUser,
  firstPlanId,
  handleCouponChange,
  selectedFeatures,
  bienniallyFreeMonths,
  priceWithAddon,
  allowCoupon,
}) => {
  const price = parseFloat(plan?.price || '0')
  const points = useMemo(() => (
    plan?.item && generateFeaturesList(plan.item, activateNow)
  ), [plan?.item, activateNow])

  const planPeriod = plan?.period || 12
  const period = { 1: 'month', 12: 'year', 24: '2 years' }[planPeriod]

  const [EUPOriginal, EUPDiscounted, EUPPrice] = useMemo<[string, string, number]>(() => {
    if (!extraUserAddon) {
      return ['', '', 0]
    }

    const basePrice = new Decimal(extraUserAddon.price)
    const discountPrice = basePrice.minus(calculateCouponDiscount(basePrice, coupon)).toNumber()
    const options = { maximumFractionDigits: 0, minimumFractionDigits: 0 }
    return [toMoney(basePrice.toNumber(), options), toMoney(discountPrice, options), discountPrice]
  }, [extraUserAddon, coupon])

  const userCount = (plan?.item?.users || 1) + (addAnotherUser ? 1 : 0)
  const nameParts = (plan?.name || '').replace(/\)$/, '').split('(')

  const renderHeaderComponent = () => (
    <SummaryCardHeader
      userCount={userCount}
      coupon={coupon}
      price={price}
      planPeriod={planPeriod}
      activateNow={activateNow}
      bienniallyFreeMonths={bienniallyFreeMonths}
    />
  )

  return (
    <SimpleCard className="checkout-summary-card">
      <div className="first-block">
        <SimpleBox
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          gap={12}
        >
          <img src={ShieldRedIcon} alt="shield" width={42} />
          <SimpleBox
            display="flex"
            flexWrap="wrap"
            justifyContent="flex-start"
            alignItems="center"
            gap={4}
          >
            <SimpleText size="title3" color="red" whiteSpace="nowrap">
              {nameParts[0]}
            </SimpleText>
            <SimpleText size="title3">
              ({nameParts[1]})
            </SimpleText>
          </SimpleBox>
        </SimpleBox>
        <SimpleBox width="100%" flexDirection="column" alignItems="flex-start" marginTop="12px" className="hide-on-tablet-only">
          {renderHeaderComponent()}
        </SimpleBox>
        <Points
          items={points}
          secondary={true}
          footer={null}
          newCheckout={true}
        />
        {extraUserAddon && (
          <SimpleBox className="summary-card-extra-features">
            <SimpleText size="buttonText" color="grey">
              Protect Your Family
            </SimpleText>
            <SimpleBox
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              gap={4}
              marginTop={8}
            >
              <Checkbox
                className="add-one-checkbox"
                onChange={handleToggleAddUser}
                style={{ isolation: 'isolate' }}
                checked={addAnotherUser}
                toggle
              />
              <SimpleText size="text5">
                Add user for{' '}
                {(EUPOriginal !== EUPDiscounted) && (
                  <span className="checkout-text-crossed">{EUPOriginal}</span>
                )}
                {' '}{(
                  <span className={(EUPOriginal !== EUPDiscounted) ? 'green-text' : ''}>{EUPDiscounted}</span>
                )}
                /{period}
              </SimpleText>
              <SimpleBox
                display="flex"
                flexDirection="column"
                position="relative"
                className="alert-box"
              >
                <Label pointing="below" className="alert-label">
                  Add a friend or family member and they will get their own login
                  and access to all the same features at a special rate.
                </Label>
                <img src={AlertIcon} alt="alert" width={16} />
              </SimpleBox>
            </SimpleBox>
          </SimpleBox>
        )}
      </div>
      <div className="second-block">
        <SimpleBox width="100%" flexDirection="column" alignItems="flex-start" marginTop="12px" className="show-on-tablet-only">
          {renderHeaderComponent()}
        </SimpleBox>
        <Breakdown
          plan={plan}
          total={total}
          coupon={coupon}
          activateNow={activateNow}
          priceWithAddon={priceWithAddon}
          selectedFeatures={selectedFeatures}
          bienniallyFreeMonths={bienniallyFreeMonths}
        />
        {allowCoupon && (
          <div className="discountForm">
            <Discount
              planId={firstPlanId}
              current={coupon}
              onCouponFound={handleCouponChange}
              onRemoveCoupon={handleCouponChange}
            />
          </div>
        )}
      </div>
    </SimpleCard>
  )
}

export default SummaryCard
