import React, { FC, useEffect } from 'react'
import { Header, Loader } from 'semantic-ui-react'
import { Dashboard } from '../components/IdentitySecurity/Dashboard'
import { Onboarding } from '../components/IdentitySecurity/Onboarding'
import { SimpleBox } from '../components/SimpleBox'
import { SimpleLayout } from '../components/SimpleLayout'
import { useNavigation } from '../hooks/useNavigation'
import {gql, useQuery} from '@apollo/client'

const IdentitySecurity: FC = () => {
  const { setNav, resetNav } = useNavigation()
  const { data, loading, refetch } = useQuery(
    gql`
      query {
        account {
          identisafeUserId
        }
      }
    `
    , { fetchPolicy: 'no-cache' })

  const isLoading = (data === null || data === undefined) && loading
  const isOnboarded = data?.account?.identisafeUserId != null

  useEffect(() => {
    setNav('hidden')
    return resetNav
  }, [])

  return (
    <SimpleLayout>
      <SimpleBox className="identity-security">
        <Header as="h4">Identity Security</Header>
        {isLoading && (<Loader active />)}
        {!isLoading && isOnboarded && <Dashboard />}
        {!isLoading && !isOnboarded && <Onboarding onSetupComplete={refetch} />}
      </SimpleBox>
    </SimpleLayout>)
}

IdentitySecurity.displayName = 'IdentitySecurity'

export default IdentitySecurity
